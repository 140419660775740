
.videoContainer {
    position: relative;
    width: 100%;
    height: 315px;
  }
  
  .videoLoader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  