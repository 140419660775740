.page_title {
    font-family: "OpenSansLight";
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    margin: 0px !important;
    color: #777;
    text-decoration: none !important;
}

.topNav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
}

.demoVideoWrapper {
    width: 100%;
    padding: 70px;
}
.demoInnerWrapper {
    max-width: 1306px;
}

.iframeContainer {
    border-radius: 8px;
    background: #FFF;
    padding: 15px 14px 16px;
}

.iframeInnerWrapper {
    position: relative;
    padding-bottom: calc(52.2917% + 41px);
    height: 0;
    width: 100%;
}

.iframeVideo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color-scheme: light;
}

.loaderWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 70vh;
}

.selectContainer {
    max-width: 331px;
    width: 100%;
    margin-bottom: 12px;
}
.infoLinkWrapper{
    margin-top: 17px;
    flex-direction: row;
    justify-content: start;
    align-items: center;
}
@media (max-width: 1919px) {
    .demoInnerWrapper {
      max-width: 80%; 
    }
  }