.cardWrapper {
    width: 377px;
    border-radius: 10px;
    border: 1px solid #ECECEC;
    background: #FFF;
    box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.02);
}

.cardBodyContainer {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 12px;
}

.iconWrapper {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.icon {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.description {
    color: #6D6D6D;
    font-family: 'OpenSansRegular';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    margin: 0px !important;
}
.contentWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
}
.content {
    height: 112px;
}

.cardBody{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 10px;
    border: 1px solid #ECECEC;
    background: #FFF;
    box-shadow: 0px 0px 19px 0px rgba(0, 0, 0, 0.02);
    gap: 8px;
    height: auto;
}
@media (max-width: 768px) {
    .cardBody {
        height: auto;
    }
}
