.demoCardBody{
    padding: 20px 26px 26px;
    cursor: default;
}
.demoCardWrapper:hover {
    opacity: 0.5;
  }
.demoCardWrapper{
    position: relative;
}
.demoImage{
    width: 100%;
}
.demoCardDesc{
    color: #6D6D6D;
    font-family: 'OpenSansRegular';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    margin: 0px !important;
    padding-top: 10px;
}
.imageContainer {
    position: relative;
  }
  
  .hoverButton {
    display: none;
    position: absolute; 
    top: 58%; 
    left: 50%;
    transform: translate(-50%, -50%); 
    cursor: pointer;
  }
  
  .demoCardWrapper:hover .hoverButton {
    display: block;
  }