.outlineBtn {
    border-radius: 5px;
    border: 0.5px solid #99DFFF;
    background: #FFF;
    width: 129px;
    height: 30px;
    padding: 0px !important;
    font-family: 'OpenSansBold';
    font-size: 12px;
    font-weight: 700;
    color: #01348D;
}

.outlineBtn:hover {
    color: #FFF;
    background-color: #01348D;
    border-color: #01348D;
}